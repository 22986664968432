#container {
  position: relative;
}

#wrapper {
  margin: 0 auto;
  padding: 40px;
}

#background {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;

  border-radius: 20px;
  background-color: white;
  z-index: -9999;
  opacity: 0.5;
}

body {
  background: #77270d url("../img/ArmstrongAuditoriumTheater.jpg") no-repeat fixed center top;
}

#comment_form legend {
  text-shadow: none;
}

img.logo {
  margin: 0px 20px;
}

a, a:visited {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.shadow {
  color: white;
  text-shadow: 2px 2px 4px black;
}

.shadow a {
  color: inherit;
}

.privacy a,
.privacy a:visited,
.aicf-closed a,
.aicf-closed a:visited {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

footer {
  clear: both;
  padding-top: 50px;
  margin-bottom: 10px;
}

#recent-events {
  margin-top: 40px;
}

#comments {
  max-width: 640px;
  margin: 0 auto;
}

form legend {
  color: white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#video-container {
  max-width: 1280px;
  margin: 0 auto;
}

span.user {
  margin-right: 10px;
}
